import React, { useEffect, useState } from "react";
import styled from "styled-components";
import DCCLogo from "../../../assets/DCC-Logo.svg";
import logo from "../../../assets/images/shareholder-app.svg";
import logos from "../../../assets/share-registrar.svg";
import Spinner from "../../common/spinner";

import { useHistory } from "react-router";
import { numberWithCommas } from "../../../utilities/utilityFunctions";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import "./evotingStyle.scss";
import {
  ByCNICForVote,
  ByCNICForVotingDetails,
  checkResolutionvote,
  getAllElectionOfDirector,
  getAllSpecialAgenda,
  getCompaniesforVoting,
  getCompaniesforVotingByCompanyCodes,
  getSharesByCNIC,
} from "../../../store/services/evoting.service";
import moment from "moment";
import { Logout } from "./logout";
import { ToastContainer, toast } from "react-toastify";
import Nav from "react-bootstrap/Nav";
import {
  getCurrentOfferingsAndSubscriptions,
  getInvestorDetails,
} from "../../../store/services/publicOfferings.service";
import { geAllActiveCompanies } from "../../../store/services/company.service";

export const EvotingHome = () => {
  const isSmallScreen = window.matchMedia("(max-width: 500px)").matches;
  const getTempEmail = JSON.parse(sessionStorage.getItem("email"));
  const getUserData = JSON.parse(sessionStorage.getItem("user_data"));
  const getUserCompany = JSON.parse(sessionStorage.getItem("user_company"));
  const [specialVotingDetail, setSpecialVotingDetail] = useState(false);
  const currentDate = new Date();
  const history = useHistory();
  const [electionofDirectors, setElectionofDirectors] = useState([]);
  const [specialAgenda, setSpecialAgenda] = useState([]);
  const [allCompanies, setAllCompanies] = useState([]);
  const [uniqueSpecialAgenda, setUniqueSpecialAgenda] = useState([]);
  const [loading, setLoading] = useState(true);
  const [votedDirectorElection, setVotedDirectorElection] = useState([]);
  const [votedResolutionElection, setVotedResolutionElection] = useState([]);
  const [directorVotingView, setDirectorVotingView] = useState([]);

  const [votedDirectorDetail, setVotedDirectorDetail] = useState({});
  const [resolutionVotingView, setResolutioVotingView] = useState([]);

  const [votedResolutionDetail, setVotedResolutionDetail] = useState({});

  const [showDirectorVotingDetail, setShowDirectorVotingDetail] =
    useState(false);

  let splitCategory = JSON.parse(directorVotingView?.vote_casting || "[]");
  let femaleConidate = splitCategory.filter(
    (item) => item.category === "female_candidate"
  );
  let independentConidate = splitCategory.filter(
    (item) => item.category === "independent_candidate"
  );
  let nonIndependentConidate = splitCategory.filter(
    (item) => item.category === "non_independent_candidate"
  );
  const [termCondition, setTermCondition] = useState(false);
  const [viewFlag, setViewFlag] = useState(false);
  const [viewAgendaData, setViewAgendaData] = useState({});
  let totalvotes = 400;
  let preViewTotalVotes = 0;
  const [currentPage, setCurrentPage] = useState("home");

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [ipoData, setIpoData] = useState([]);
  const [subscriptionData, setSubscriptionData] = useState([]);
  // const [showIpoSubscriptionModal, setShowIpoSubscriptionModal] =
  //   useState(false);

  useEffect(() => {
    const getInvestorDetailsByCNIC = async () => {
      const response = await getInvestorDetails(
        getTempEmail,
        getUserData.cnic,
        getUserData.type
      );

      if (response.data.status === 200) {
        let investorDetails = response.data.investorDetails;
        sessionStorage.setItem(
          "investorDetails",
          JSON.stringify(investorDetails)
        );
      } else {
        sessionStorage.setItem("investorDetails", "{}"); // Convert empty array to string
      }
    };

    getInvestorDetailsByCNIC();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    setWindowWidth(window.innerWidth);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setLoading(true);
    const getCurrentIPOData = async () => {
      try {
        const response = await getCurrentOfferingsAndSubscriptions(
          getTempEmail,
          getUserData?.cnic
        );

        if (response.data.status == 200) {
          let subs = [];
          if (response.data.data.length > 0) {
            response.data.data = response.data.data.filter((item) => {
              return (
                item.ipo_percentage > 0 &&
                item.lot_size > 0 &&
                item.final_offer_price > 0 &&
                item.payment_accounts &&
                item.payment_accounts.length > 0
              );
            });
          }
          if (response.data.subscriptions.length > 0) {
            for (let i = 0; i < response.data.subscriptions.length; i++) {
              subs.push(response.data.subscriptions.offer_id);
            }
          }
          if (subs && subs.length > 0) {
            for (let i = 0; i < response.data.data.length; i++) {
              if (subs.includes(response.data.data[i].offer_id)) {
                response.data.data[i].subscribed = true;
              }
            }
          }
          setIpoData(response.data.data);
          setSubscriptionData(response.data.subscriptions);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        // console.log("Error => ", error.message);
      }
    };

    const getAllCompany = async () => {
      try {
        // const response = await getCompaniesforVotingByCompanyCodes(
        //   getTempEmail,
        //   getUserCompany.join(",")
        // );

        const response = await geAllActiveCompanies(getTempEmail);
        const res = await ByCNICForVotingDetails(
          getTempEmail,
          getUserData?.cnic,
          getUserCompany.join(",")
        );
        if (response.data.status == 200) {
          // console.log(response.data.data);
          setAllCompanies(response.data.data);
          getCurrentIPOData();
        } else {
          // toast.error("No Record Found");
          setLoading(false);
        }
        if (res.data.status == 200) {
          let agendas = res.data.special_agendas.filter((item) => {
            return item.agendas && item.agendas != "";
          });
          setSpecialAgenda(agendas);
          setVotedDirectorElection(res.data.director_elections_votes);
          setVotedResolutionElection(
            res.data.special_agendas_votes.filter((item) => {
              return (
                item.vote != "" &&
                item.vote != "Disapproved" &&
                item.vote != "Approved"
              );
            })
          );
          let temp1 = [];
          let temp2 = [];
          res.data.director_elections.map((item) => {
            for (let i = 0; i < res.data.data.length; i++) {
              if (
                item.company_code == res.data.data[i].company_code &&
                parseFloat(
                  item.number_of_candidates ? item.number_of_candidates : "0"
                ) >
                  parseFloat(
                    item.number_of_directors ? item.number_of_directors : "0"
                  ) &&
                moment
                  .duration(moment().diff(moment(item.election_to)))
                  .asDays() < 3
              ) {
                temp2.push({
                  data: item,
                  company_code: item.company_code,
                  folio_number: res.data.data[i].folio_number,
                  holding:
                    parseFloat(res.data.data[i].electronic_shares) +
                    parseFloat(res.data.data[i].physical_shares),
                });
              }
            }
          });
          setElectionofDirectors(temp2);
          agendas.map((item) => {
            for (let i = 0; i < res.data.data.length; i++) {
              if (
                item.company_code == res.data.data[i].company_code &&
                moment
                  .duration(moment().diff(moment(item.agenda_to)))
                  .asDays() < 3
              ) {
                let agendas = JSON.parse(item.agendas).filter((item) => {
                  return item.status && item.status == "active";
                });
                item.agendas = agendas ? JSON.stringify(agendas) : "[]";
                temp1.push({
                  id: item.meeting_id,
                  company_code: item.company_code,
                  folio_number: res.data.data[i].folio_number,
                  data: item,
                  holding:
                    parseFloat(res.data.data[i].electronic_shares) +
                    parseFloat(res.data.data[i].physical_shares),
                });
              }
            }
          });
          setUniqueSpecialAgenda(temp1);
          if (temp1.length == 0 && temp2.length == 0) {
            // toast.error("No Record Found");
          }
          setLoading(false);
        } else {
          // toast.error("No Record Found");
          setLoading(false);
        }
      } catch (error) {
        // toast.error("No Record Found");
        setLoading(false);
      }
    };

    getAllCompany();
  }, []);

  window.addEventListener("popstate", () => {
    history.replace(`${process.env.PUBLIC_URL}/evoting/home`);
  });

  const dummyArrayforEvoting = [
    {
      logo: DCCLogo,
      title: "Election of Directors",
      startdate: "12-Mar-2023",
      endDate: "18-Mar-2023",
      Name: "touseeq",
    },
    {
      logo: DCCLogo,
      title: "Special Resolution",
      startdate: "20-Mar-2023",
      endDate: "23-Mar-2023",
      castVote: true,
      Name: "touseeq",
    },
    {
      logo: DCCLogo,
      title: "Election of Directors",
      startdate: "18-Mar-2023",
      endDate: "21-Mar-2023",
      specialvoting: true,
      Name: "touseeq",
    },
    {
      logo: DCCLogo,
      title: "Election of Directors",
      startdate: "18-Mar-2023",
      endDate: "21-Mar-2023",
      electionvoting: true,
      Name: "touseeq",
    },
    // {
    //     logo: DCCLogo,
    //     title: 'Election of Directors',
    //     startdate: '24-Mar-2023',
    //     endDate: '28-Mar-2023'
    // }
  ];

  const handleIPOSubscription = (data) => {
    const investorDetails = JSON.parse(
      sessionStorage.getItem("investorDetails")
    );
    if (
      getUserData.type !== "cnic" &&
      (!investorDetails ||
        !investorDetails.cnic ||
        investorDetails.cnic.trim() === "")
    ) {
      toast.error(
        "CNIC required for IPO subscription. NO CNIC details found. Contact Support!"
      );
      return;
    }
    const Jsondata = JSON.stringify(data);
    sessionStorage.setItem("ipoData", Jsondata);
    history.push(`${process.env.PUBLIC_URL}/ipo-subscription`);
  };

  const handleIPOSubscriptionPayment = (offerData, subscriptionData) => {
    const investorDetails = JSON.parse(
      sessionStorage.getItem("investorDetails")
    );
    if (
      getUserData.type !== "cnic" &&
      (!investorDetails ||
        !investorDetails.cnic ||
        investorDetails.cnic.trim() === "")
    ) {
      toast.error(
        "CNIC required for IPO subscription. NO CNIC details found. Contact Support!"
      );
      return;
    }
    const Jsondata = JSON.stringify(offerData);
    sessionStorage.setItem("ipoData", Jsondata);

    const jsondata2 = JSON.stringify(subscriptionData);
    sessionStorage.setItem("subscriptionData", jsondata2);
    history.push(`${process.env.PUBLIC_URL}/ipo-subscription-payment`);
  };

  const handleVoteCast = (data) => {
    const Jsondata = JSON.stringify(data);
    sessionStorage.setItem("evotingData", Jsondata);
    history.replace(`${process.env.PUBLIC_URL}/voting`);
  };

  const handleSpecialResolutionVoteCast = (data) => {
    const Jsondata = JSON.stringify(data);
    sessionStorage.setItem("evotingData", Jsondata);
    history.push(`${process.env.PUBLIC_URL}/resolution-voting`);
  };

  const handleViewResolution = (data) => {
    history.push(`${process.env.PUBLIC_URL}/special-resolution`);
  };

  const viewElectionResult = (data) => {
    history.push(`${process.env.PUBLIC_URL}/election-voting-result`, {
      data: dummyArrayforEvoting,
      result: data,
    });
  };

  const viewSpecialVotingResult = (data) => {
    history.push(`${process.env.PUBLIC_URL}/special-voting-result`, {
      data: dummyArrayforEvoting,
      result: data,
    });
  };

  return (
    <>
      <ToastContainer />
      <Modal
        isOpen={specialVotingDetail}
        show={specialVotingDetail.toString()}
        size="sm"
      >
        <ModalHeader
          toggle={() => {
            setSpecialVotingDetail(false);
          }}
        >
          Agenda Voting Detail
        </ModalHeader>
        <ModalBody>
          <div className="evotingfontfamily">
            <div className="row mt-4 ">
              <ModelPaddingWrapper className="col-sm-12 col-md-12 col-lg-12 responsivepage">
                <div
                  className="card pb-5 "
                  style={{ border: "1px solid #70ace7" }}
                >
                  <Wrapper
                    className="card-header "
                    style={{ borderBottom: "none" }}
                  >
                    <div className="responsivemodelpaddingpage">
                      <div className="text-center mt-3">
                        <img
                          src={
                            votedResolutionDetail?.logo
                              ? votedResolutionDetail?.logo
                              : require("../../../assets/images/defaultImg.png")
                          }
                          alt=""
                          style={{
                            maxHeight: "120px",
                            maxWidth: "180px",
                            width: "auto",
                            height: "auto",
                          }}
                        />
                      </div>
                    </div>
                  </Wrapper>

                  <div
                    className=" mt-3"
                    style={{ justifyContent: "center", display: "flex" }}
                  >
                    <div>
                      <div
                        style={{
                          color: "#447bc9",
                          fontSize: "23px",
                          textAlign: "center",
                        }}
                      >
                        Special Resolution
                      </div>
                      <p
                        style={{
                          fontSize: "16px",
                          color: "rgb(152, 161, 181)",
                          textAlign: "center",
                        }}
                        className="card-text responsivemodelpaddingpage"
                      >
                        {votedResolutionDetail?.from_date}
                        <br /> TO <br /> {votedResolutionDetail?.to_date}
                      </p>
                      <div style={{ wordBreak: "break-word" }}>
                        {resolutionVotingView &&
                          resolutionVotingView.vote &&
                          JSON.parse(resolutionVotingView.vote).map(
                            (item, index) => {
                              return (
                                <div
                                  style={{
                                    textAlign: "center",
                                    marginBottom: "5px",
                                  }}
                                >
                                  <div style={{ color: "rgb(152, 161, 181)" }}>
                                    Special Resolution {index + 1}
                                  </div>
                                  <div
                                    style={{
                                      color: "#000000",
                                      padding: "0px 10px",
                                    }}
                                  >
                                    {item.agenda_title}
                                  </div>
                                  <div
                                    className={
                                      item?.votes_favour == "1"
                                        ? "text-success"
                                        : "text-danger"
                                    }
                                  >
                                    {item?.votes_favour == "1"
                                      ? "favour".toUpperCase()
                                      : "against".toUpperCase()}
                                  </div>
                                </div>
                              );
                            }
                          )}
                      </div>
                    </div>

                    <div></div>
                  </div>
                </div>
              </ModelPaddingWrapper>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={showDirectorVotingDetail}
        show={showDirectorVotingDetail.toString()}
        size="sm"
      >
        <ModalHeader
          toggle={() => {
            setShowDirectorVotingDetail(false);
          }}
        >
          Director Voting Detail
        </ModalHeader>
        <ModalBody>
          <div className="evotingfontfamily">
            <MainWrapper className="row mt-4">
              <ModelPaddingWrapper className="col-sm-12 col-md-12 col-lg-12 responsivepage ">
                <div
                  className="card pb-5"
                  style={{ border: "1px solid #70ace7" }}
                >
                  <Wrapper
                    className="card-header "
                    style={{ borderBottom: "none" }}
                  >
                    <div className="responsivemodelpaddingpage">
                      <div className="text-center mt-1">
                        <img
                          src={
                            votedDirectorDetail?.logo
                              ? votedDirectorDetail?.logo
                              : require("../../../assets/images/defaultImg.png")
                          }
                          alt=""
                          style={{
                            maxHeight: "120px",
                            maxWidth: "180px",
                            width: "auto",
                            height: "auto",
                          }}
                        />
                      </div>
                      <div
                        className="mt-3"
                        style={{
                          color: "#447bc9",
                          fontSize: "23px",
                          display: "flex",
                          justifyContent: "center",
                          textAlign: "center",
                        }}
                      >
                        Election of Directors
                      </div>
                      <p
                        style={{
                          fontSize: "16px",
                          display: "flex",
                          justifyContent: "center",
                          color: "rgb(152, 161, 181)",
                          textAlign: "center",
                        }}
                        class="card-text"
                      >
                        {votedDirectorDetail?.from_date} <br /> TO <br />
                        {votedDirectorDetail?.to_date}
                      </p>

                      <div
                        className="mt-3"
                        style={{
                          justifyContent: "center",
                          display: "flex",
                          fontSize: "20px",
                        }}
                      >
                        Your Votes
                      </div>
                    </div>
                  </Wrapper>

                  {femaleConidate.length > 0 && (
                    <div
                      className="card-body pt-3 pb-3 mt-5 "
                      style={{
                        justifyContent: "center",
                        textAlign: "center",
                        display: "flex",
                        wordBreak: "break-word",
                        borderRadius: "10px",
                      }}
                    >
                      <div
                        style={{
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <h5>
                          <b>Female Candidates</b>
                        </h5>
                        {femaleConidate.map((item, key) => {
                          preViewTotalVotes =
                            preViewTotalVotes +
                            (Number(item?.votes_casted) || 0);
                          return (
                            <>
                              <div key={key} class="row no-gutters">
                                <div class="col-md-12 mt-3">
                                  <div
                                    style={{
                                      maxWidth: "260px",
                                      textAlign: "center",
                                      fontSize: "18px",
                                      color: "#5B9BD5",
                                      alignItems: "center",
                                    }}
                                  >
                                    {key + 1}
                                    {". "}
                                    <span style={{ paddingLeft: "5px" }}>
                                      {" "}
                                      {item?.candidate_name?.toUpperCase()}
                                    </span>
                                  </div>
                                  <div
                                    style={{
                                      fontSize: "20px",
                                      maxWidth: "260px",
                                      textAlign: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    {" "}
                                    {numberWithCommas(item?.votes_casted) ||
                                      0}{" "}
                                    <span
                                      style={{
                                        fontSize: "14px",
                                        paddingLeft: "8px",
                                        color: "rgb(152, 161, 181)",
                                      }}
                                    >
                                      votes
                                    </span>
                                  </div>
                                  <hr style={{ width: "60%" }} />
                                </div>
                              </div>
                            </>
                          );
                        })}
                      </div>
                    </div>
                  )}

                  {independentConidate.length > 0 && (
                    <div
                      className="card-body card-body pt-3 pb-3 mt-5 "
                      style={{
                        justifyContent: "center",
                        textAlign: "center",
                        display: "flex",
                        wordBreak: "break-word",
                        borderRadius: "10px",
                      }}
                    >
                      <div
                        style={{
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <h5>
                          <b>Independent Candidates</b>
                        </h5>
                        {independentConidate.map((item, key) => {
                          preViewTotalVotes =
                            preViewTotalVotes +
                            (Number(item?.votes_casted) || 0);
                          return (
                            <>
                              <div key={key} class="row no-gutters">
                                <div class="col-md-12 mt-3">
                                  <div
                                    style={{
                                      maxWidth: "260px",
                                      textAlign: "center",
                                      fontSize: "18px",
                                      color: "#5B9BD5",
                                      alignItems: "center",
                                    }}
                                  >
                                    {key + 1}
                                    {". "}
                                    <span style={{ paddingLeft: "5px" }}>
                                      {" "}
                                      {item?.candidate_name?.toUpperCase()}
                                    </span>
                                  </div>
                                  <div
                                    style={{
                                      fontSize: "20px",
                                      maxWidth: "260px",
                                      textAlign: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    {" "}
                                    {numberWithCommas(item?.votes_casted) ||
                                      0}{" "}
                                    <span
                                      style={{
                                        fontSize: "14px",
                                        paddingLeft: "8px",
                                        color: "rgb(152, 161, 181)",
                                      }}
                                    >
                                      votes
                                    </span>
                                  </div>
                                  <hr style={{ width: "60%" }} />
                                </div>
                              </div>
                            </>
                          );
                        })}
                      </div>
                    </div>
                  )}
                  {nonIndependentConidate.length > 0 && (
                    <div
                      className="card-body pt-3 pb-3 mt-5 "
                      style={{
                        justifyContent: "center",
                        textAlign: "center",
                        display: "flex",
                        wordBreak: "break-word",
                        borderRadius: "10px",
                      }}
                    >
                      <div
                        style={{
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <h5>
                          {/* <b>Non Independent Candidates</b> */}
                          <b>Other Candidates</b>
                        </h5>
                        {nonIndependentConidate.map((item, key) => {
                          preViewTotalVotes =
                            preViewTotalVotes +
                            (Number(item?.votes_casted) || 0);
                          return (
                            <>
                              <div key={key} class="row no-gutters">
                                <div class="col-md-12 mt-3">
                                  <div
                                    style={{
                                      maxWidth: "260px",
                                      textAlign: "center",
                                      fontSize: "18px",
                                      color: "#5B9BD5",
                                      alignItems: "center",
                                    }}
                                  >
                                    {key + 1}
                                    {". "}
                                    <span style={{ paddingLeft: "5px" }}>
                                      {" "}
                                      {item?.candidate_name?.toUpperCase()}
                                    </span>
                                  </div>
                                  <div
                                    style={{
                                      fontSize: "20px",
                                      maxWidth: "260px",
                                      textAlign: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    {" "}
                                    {numberWithCommas(item?.votes_casted) ||
                                      0}{" "}
                                    <span
                                      style={{
                                        fontSize: "14px",
                                        paddingLeft: "8px",
                                        color: "rgb(152, 161, 181)",
                                      }}
                                    >
                                      votes
                                    </span>
                                  </div>
                                  <hr style={{ width: "60%" }} />
                                </div>
                              </div>
                            </>
                          );
                        })}
                      </div>
                    </div>
                  )}

                  <div
                    className="card-body pt-3 pb-3 mt-5 "
                    style={{
                      justifyContent: "center",
                      textAlign: "center",
                      display: "flex",
                      wordBreak: "break-word",
                      borderRadius: "10px",
                    }}
                  >
                    <div>
                      <h5>
                        <b>Total Votes</b>
                      </h5>
                      <div
                        style={{
                          alignItems: "center",
                          fontSize: "18px",
                        }}
                      >
                        Total Votes:{" "}
                        <span style={{ color: "#5B9BD5" }}>
                          {" "}
                          {numberWithCommas(directorVotingView.castable_votes)}
                        </span>
                      </div>
                      <div style={{ alignItems: "center", fontSize: "18px" }}>
                        Votes Casted:{" "}
                        <span style={{ color: "#5B9BD5" }}>
                          {" "}
                          {numberWithCommas(directorVotingView.castable_votes)}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </ModelPaddingWrapper>
            </MainWrapper>
          </div>
        </ModalBody>
      </Modal>

      <div className="evotingfontfamily" style={{ padding: "20px" }}>
        <ToastContainer />
        <MainWrapper className="row mt-4">
          <ResponsiveWrapper className="col-sm-12 col-md-12 col-lg-12 responsivepage">
            <div
              className="card pb-5 responsivhomePage"
              style={{ border: "1px solid #70ace7" }}
            >
              <div
                className="row mr-3 mt-2"
                style={{ justifyContent: "right" }}
              >
                <Logout />
              </div>
              <Wrapper
                className="card-header"
                style={{ borderBottom: "none", marginBottom: "-12px" }}
              >
                <div className="text-center">
                  <img src={logos} alt="" className="py-3" width="220" />
                </div>
                {/* <hr className="responsive mt-4" /> */}
                <Nav
                  fill
                  variant="tabs"
                  defaultActiveKey={
                    currentPage === "home"
                      ? "home"
                      : currentPage === "portfolio"
                      ? "portfolio"
                      : "invest"
                  }
                  style={{ border: "none", display: "flex" }}
                >
                  <Nav.Item>
                    <Nav.Link
                      eventKey="home"
                      style={{
                        color: currentPage === "home" ? "#fff" : "#000",
                        backgroundColor:
                          currentPage === "home" ? "#5B9BD5" : "#ffffff",
                        borderColor: "#5B9BD5",
                        borderTop: "1px solid #5B9BD5",
                        borderTopLeftRadius: "5px",
                        borderTopRightRadius: "5px",
                        // marginLeft: "15%",
                        padding: windowWidth <= "768" && "5% 0%",
                        // marginBottom: "0",
                        height: "100%",
                      }}
                      onClick={() => {
                        setCurrentPage("home");
                        setLoading(false);
                      }}
                    >
                      Home
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="portfolio"
                      style={{
                        color: currentPage === "portfolio" ? "#fff" : "#000",
                        backgroundColor:
                          currentPage === "portfolio" ? "#5B9BD5" : "#ffffff",
                        borderColor: "#5B9BD5",
                        borderTopLeftRadius: "5px",
                        borderTopRightRadius: "5px",
                        // marginRight: "15%",
                        padding: windowWidth <= "768" && "5% 0%",
                        // marginBottom: "0",
                        borderTop: "1px solid #5B9BD5",
                        height: "100%",
                      }}
                      onClick={() => {
                        setCurrentPage("portfolio");
                        setLoading(false);
                      }}
                    >
                      Portfolio
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="invest"
                      style={{
                        color: currentPage === "invest" ? "#fff" : "#000",
                        backgroundColor:
                          currentPage === "invest" ? "#5B9BD5" : "#ffffff",
                        borderColor: "#5B9BD5",
                        borderTopLeftRadius: "5px",
                        borderTopRightRadius: "5px",
                        // marginRight: "15%",
                        padding: windowWidth <= "768" && "5% 0%",
                        // marginBottom: "0",
                        borderTop: "1px solid #5B9BD5",
                        height: "100%",
                      }}
                      onClick={() => {
                        setCurrentPage("invest");
                        setLoading(false);
                      }}
                    >
                      Invest
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Wrapper>

              <div style={{ borderTop: "1px solid #5B9BD5" }}>
                {currentPage &&
                  (currentPage === "home" || currentPage === "invest") && (
                    <>
                      <div style={{ width: "100%", marginTop: "0.5rem" }}>
                        <h6
                          style={{
                            textAlign: "center",
                            marginBottom: "0",
                            fontWeight: "lighter",
                            color: "rgba(0,0,0,0.5)",
                          }}
                        >
                          Ongoing IPO
                        </h6>
                        <hr style={{ width: "75%", marginTop: "10px" }} />
                      </div>
                      <div
                        className="card-body mt-4"
                        style={{ justifyContent: "center", display: "flex" }}
                      >
                        {ipoData && ipoData.length > 0 && (
                          <div>
                            {ipoData &&
                              ipoData.length > 0 &&
                              ipoData?.map((item, key) => {
                                let startDate;
                                let endDate;
                                let filter = allCompanies.find((code) => {
                                  return code.code === item?.company_code;
                                });

                                let index = subscriptionData.findIndex(
                                  (x) => x.offer_id == item.offer_id
                                );

                                let currentSubscription = subscriptionData.find(
                                  (x) => x.offer_id == item.offer_id
                                );

                                startDate = new Date(item?.ipo_from);
                                endDate = new Date(item?.ipo_to);

                                return (
                                  <>
                                    <div key={item.offer_id}>
                                      <div class="col-md-12">
                                        <div
                                          style={{
                                            alignItems: "center",
                                          }}
                                        >
                                          <div
                                            style={{
                                              alignItems: "center",
                                              justifyContent: "center",
                                              textAlign: "center",
                                            }}
                                          >
                                            <div>
                                              <img
                                                src={
                                                  filter?.logo
                                                    ? filter?.logo
                                                    : require("../../../assets/images/defaultImg.png")
                                                }
                                                class="card-img"
                                                alt="Logo"
                                                style={{
                                                  width: "auto",
                                                  height: "auto",
                                                  maxWidth: "220px",
                                                  maxHeight: "120px",
                                                }}
                                              />
                                            </div>
                                            <br />
                                            <p
                                              style={{
                                                marginTop: "-20px",

                                                marginBottom: "10px",
                                              }}
                                            >
                                              {filter?.company_name}
                                            </p>
                                          </div>

                                          <div style={{ textAlign: "center" }}>
                                            <h5
                                              class="card-title"
                                              style={{
                                                wordWrap: "break-word",
                                              }}
                                            >
                                              Initial Public Offering
                                            </h5>

                                            <p
                                              style={{
                                                marginTop: "-5px",
                                                paddingRight: "10px",
                                                maxWidth: "335px",
                                              }}
                                              class="card-text"
                                            >
                                              {moment(item?.ipo_from).format(
                                                "DD-MMM-YYYY"
                                              )}
                                              <br />
                                              TO
                                              <br />
                                              {moment(item?.ipo_to).format(
                                                "DD-MMM-YYYY"
                                              )}
                                            </p>
                                            <div>
                                              {new Date(currentDate).getTime() <
                                              new Date(startDate).getTime() ? (
                                                <button
                                                  className="btn"
                                                  type="button"
                                                  style={{
                                                    color: "#ffff",
                                                    background: "#5B9BD5",
                                                    marginTop: "-15px",
                                                    minWidth: "170px",
                                                    maxWidth: "170px",
                                                    textAlign: "center",
                                                  }}
                                                  disabled={true}
                                                >
                                                  <span> Yet Not Started</span>
                                                </button>
                                              ) : currentSubscription &&
                                                (!currentSubscription.payment_evidence ||
                                                  currentSubscription.payment_evidence ===
                                                    "") &&
                                                (!currentSubscription.paid ||
                                                  currentSubscription.paid ===
                                                    false) ? (
                                                <button
                                                  className="btn"
                                                  type="button"
                                                  style={{
                                                    background: "#ffff",
                                                    color: "#5B9BD5",
                                                    border: "1px solid",
                                                    marginTop: "-15px",
                                                    width: "190px",
                                                    textAlign: "center",
                                                  }}
                                                  onClick={() => {
                                                    if (index < 0) {
                                                    } else {
                                                      // Pay SUBSCRIPTION MODAL???
                                                      item["logo"] =
                                                        filter?.logo;
                                                      handleIPOSubscriptionPayment(
                                                        item,
                                                        currentSubscription
                                                      );
                                                    }
                                                  }}
                                                >
                                                  Pay now
                                                </button>
                                              ) : new Date(
                                                  currentDate
                                                ).getTime() >=
                                                  new Date(
                                                    startDate
                                                  ).getTime() &&
                                                new Date(
                                                  currentDate
                                                ).getTime() <=
                                                  new Date(
                                                    endDate
                                                  ).getTime() ? (
                                                <button
                                                  className="btn"
                                                  type="button"
                                                  style={{
                                                    color: "#ffff",
                                                    background:
                                                      index < 0
                                                        ? "#5B9BD5"
                                                        : "grey",

                                                    marginTop: "-15px",
                                                    width: "190px",
                                                    textAlign: "center",
                                                  }}
                                                  disabled={
                                                    index < 0
                                                      ? // {-1 < 0
                                                        false
                                                      : true
                                                  }
                                                  onClick={() => {
                                                    if (index < 0) {
                                                      // if (-1 < 0) {
                                                      if (
                                                        new Date().getTime() >
                                                        new Date(
                                                          endDate
                                                        ).getTime()
                                                      ) {
                                                        toast.error(
                                                          "Subscription Time Expired"
                                                        );
                                                        setTimeout(() => {
                                                          window.location.reload();
                                                        }, 3000);
                                                      } else {
                                                        item["logo"] =
                                                          filter?.logo;
                                                        handleIPOSubscription(
                                                          item
                                                        );
                                                      }
                                                    } else {
                                                      // View SUBSCRIPTION MODAL???
                                                    }
                                                  }}
                                                >
                                                  {index < 0
                                                    ? // {-1 < 0
                                                      "Subscribe"
                                                    : "Subscribed"}
                                                </button>
                                              ) : (
                                                <button
                                                  className="btn"
                                                  type="button"
                                                  style={{
                                                    color: "#ffff",
                                                    background: "#5B9BD5",
                                                    marginTop: "-15px",
                                                    width: "210px",
                                                    textAlign: "center",
                                                  }}
                                                  disabled={true}
                                                >
                                                  <span>Offering Expired</span>
                                                </button>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <hr style={{ width: "100%" }} />
                                  </>
                                );
                              })}
                          </div>
                        )}
                      </div>
                      {loading && <Spinner />}
                      {!loading && ipoData.length == 0 && (
                        <div
                          style={{
                            textAlign: "center",
                            marginBottom: "1rem",
                            fontWeight: "bold",
                          }}
                        >
                          There are currently no ongoing IPO's.{" "}
                        </div>
                      )}
                    </>
                  )}

                {currentPage &&
                  (currentPage === "home" || currentPage === "portfolio") && (
                    <>
                      <div style={{ width: "100%", marginTop: "0.5rem" }}>
                        <h6
                          style={{
                            textAlign: "center",
                            marginBottom: "0",
                            fontWeight: "lighter",
                            color: "rgba(0,0,0,0.5)",
                          }}
                        >
                          Ongoing E-Voting
                        </h6>
                        <hr style={{ width: "75%", marginTop: "10px" }} />
                      </div>
                      <div
                        className="card-body mt-4"
                        style={{
                          justifyContent: "center",
                          display: "flex",
                        }}
                      >
                        {electionofDirectors &&
                          electionofDirectors.length > 0 &&
                          parseFloat(electionofDirectors[0].holding || "0") >
                            0 && (
                            <div>
                              {electionofDirectors &&
                                electionofDirectors.length > 0 &&
                                electionofDirectors?.map((item, key) => {
                                  let voted = votedDirectorElection.findIndex(
                                    (x) =>
                                      x.election_id ==
                                        item?.data?.election_id &&
                                      x.folio_number == item.folio_number
                                  );
                                  const startDate = new Date(
                                    item?.data?.election_from
                                  );
                                  const endDate = new Date(
                                    item?.data?.election_to
                                  );
                                  const filter = allCompanies.find((code) => {
                                    return code.code === item.company_code;
                                  });

                                  return (
                                    <>
                                      <div key={key}>
                                        <div class="col-md-12">
                                          <div
                                            style={{
                                              alignItems: "center",
                                            }}
                                          >
                                            <div
                                              style={{
                                                alignItems: "center",
                                                justifyContent: "center",
                                                textAlign: "center",
                                              }}
                                            >
                                              <div
                                              //style={{ width: "auto", maxWidth: "120px",
                                              // }}
                                              >
                                                <img
                                                  src={
                                                    filter?.logo
                                                      ? filter?.logo
                                                      : require("../../../assets/images/defaultImg.png")
                                                  }
                                                  class="card-img"
                                                  alt="Logo"
                                                  style={{
                                                    width: "auto",
                                                    height: "auto",
                                                    maxWidth: "220px",
                                                    maxHeight: "120px",
                                                  }}
                                                />
                                              </div>
                                              <br />
                                              <p
                                                style={{
                                                  marginTop: "-20px",
                                                  marginBottom: "10px",
                                                }}
                                                class="card-text"
                                              >
                                                {filter?.company_name}
                                              </p>
                                            </div>

                                            <div
                                              style={{ textAlign: "center" }}
                                            >
                                              <h5
                                                class="card-title"
                                                style={{
                                                  wordWrap: "break-word",
                                                }}
                                              >
                                                Election of Directors
                                              </h5>

                                              <p
                                                style={{
                                                  marginTop: "-5px",
                                                  paddingRight: "10px",
                                                  maxWidth: "335px",
                                                }}
                                                class="card-text"
                                              >
                                                {moment(
                                                  item?.data?.election_from
                                                ).format("DD-MMM-YYYY h:mmA")}
                                                <br />
                                                TO
                                                <br />
                                                {moment(
                                                  item?.data?.election_to
                                                ).format("DD-MMM-YYYY h:mmA")}
                                              </p>
                                              <div>
                                                <div
                                                  className="d-flex"
                                                  style={{
                                                    marginTop: "-5px",
                                                    justifyContent:
                                                      "space-between",
                                                    width: "100%",
                                                  }}
                                                >
                                                  <p
                                                    style={{
                                                      marginTop: "-5px",
                                                    }}
                                                    className="card-text col-md-6 text-nowrap"
                                                  >
                                                    Shares:{" "}
                                                    <b>
                                                      {numberWithCommas(
                                                        item?.holding
                                                      )}
                                                    </b>
                                                  </p>
                                                  <p
                                                    style={{
                                                      marginTop: "-5px",
                                                    }}
                                                    className="card-text col-md-6 text-nowrap"
                                                  >
                                                    Folio #:{" "}
                                                    <b>
                                                      {item?.folio_number
                                                        .split("-")
                                                        .slice(1)
                                                        .join("-")}
                                                    </b>
                                                  </p>
                                                </div>

                                                {new Date(
                                                  currentDate
                                                ).getTime() <
                                                new Date(
                                                  startDate
                                                ).getTime() ? (
                                                  <button
                                                    className="btn"
                                                    type="button"
                                                    style={{
                                                      color: "#ffff",
                                                      background: "#5B9BD5",
                                                      marginTop: "-15px",
                                                      minWidth: "170px",
                                                      maxWidth: "170px",
                                                    }}
                                                    disabled={true}
                                                  >
                                                    <span>
                                                      {" "}
                                                      Yet Not Started
                                                    </span>
                                                  </button>
                                                ) : (new Date(
                                                    currentDate
                                                  ).getTime() >=
                                                    new Date(
                                                      startDate
                                                    ).getTime() &&
                                                    new Date(
                                                      currentDate
                                                    ).getTime() <=
                                                      new Date(
                                                        endDate
                                                      ).getTime()) ||
                                                  voted > -1 ? (
                                                  <button
                                                    className="btn"
                                                    type="button"
                                                    //disabled={voted > -1 ? true : false}
                                                    style={{
                                                      color: "#ffff",
                                                      background: "#5B9BD5",
                                                      marginTop: "-15px",
                                                      width: "190px",
                                                      textAlign: "center",
                                                    }}
                                                    onClick={() => {
                                                      if (voted < 0) {
                                                        if (
                                                          new Date().getTime() >=
                                                          new Date(
                                                            endDate
                                                          ).getTime()
                                                        ) {
                                                          toast.error(
                                                            "Voting Time Expired"
                                                          );
                                                          setTimeout(() => {
                                                            window.location.reload();
                                                          }, 3000);
                                                        } else {
                                                          // localStorage.setItem(
                                                          //   "shareholdervotecast",
                                                          //   JSON.stringify(
                                                          //     item.folio_number
                                                          //   )
                                                          // );
                                                          sessionStorage.setItem(
                                                            "shareholdervotecast",
                                                            JSON.stringify(
                                                              item.folio_number
                                                            )
                                                          );
                                                          item.data["logo"] =
                                                            filter?.logo;
                                                          handleVoteCast(
                                                            item.data
                                                          );
                                                        }
                                                      } else {
                                                        setVotedDirectorDetail({
                                                          logo: filter?.logo,
                                                          from_date: moment(
                                                            item?.data
                                                              ?.election_from
                                                          ).format(
                                                            "DD-MMM-YYYY h:mmA"
                                                          ),
                                                          to_date: moment(
                                                            item?.data
                                                              ?.election_to
                                                          ).format(
                                                            "DD-MMM-YYYY h:mmA"
                                                          ),
                                                        });

                                                        setDirectorVotingView(
                                                          votedDirectorElection[
                                                            voted
                                                          ]
                                                        );
                                                        setShowDirectorVotingDetail(
                                                          true
                                                        );
                                                      }
                                                    }}
                                                  >
                                                    <span>
                                                      {voted > -1
                                                        ? "View Casted Vote"
                                                        : "Cast Vote"}
                                                    </span>
                                                  </button>
                                                ) : (
                                                  <button
                                                    className="btn"
                                                    type="button"
                                                    style={{
                                                      color: "#ffff",
                                                      background: "#5B9BD5",
                                                      marginTop: "-15px",
                                                      width: "210px",
                                                      textAlign: "center",
                                                    }}
                                                    disabled={true}
                                                  >
                                                    <span>
                                                      Voting Time Expired
                                                    </span>
                                                  </button>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <hr style={{ width: "100%" }} />
                                    </>
                                  );
                                })}
                            </div>
                          )}
                      </div>
                      {loading && <Spinner />}
                      <div
                        className="card-body mt-4"
                        style={{ justifyContent: "center", display: "flex" }}
                      >
                        {uniqueSpecialAgenda &&
                          uniqueSpecialAgenda.length > 0 &&
                          parseFloat(uniqueSpecialAgenda[0].holding || "0") >
                            0 && (
                            <div>
                              {uniqueSpecialAgenda &&
                                uniqueSpecialAgenda.length > 0 &&
                                uniqueSpecialAgenda?.map((item, key) => {
                                  let startDate;
                                  let endDate;
                                  let filter;

                                  let index = votedResolutionElection.findIndex(
                                    (x) =>
                                      x.agenda_id == item.data.item_id &&
                                      x.folio_number == item.folio_number
                                  );

                                  startDate = new Date(item?.data?.agenda_from);
                                  endDate = new Date(item?.data?.agenda_to);
                                  filter = allCompanies.find((code) => {
                                    return (
                                      code.code === item.data?.company_code
                                    );
                                  });
                                  // }
                                  {
                                    /* else {
                            index = specialAgenda.findIndex(
                              (x) =>
                                x.meeting_id == item.meeting_id &&
                                x.company_code == item.company_code
                            );
                            startDate = new Date(specialAgenda[index]?.agenda_from);
                            endDate = new Date(specialAgenda[index]?.agenda_to);
                            filter = allCompanies.find((code) => {
                              return (
                                code.code === specialAgenda[index]?.company_code
                              );
                            });
                          } */
                                  }

                                  return (
                                    <>
                                      <div key={key}>
                                        <div class="col-md-12">
                                          <div
                                            style={{
                                              alignItems: "center",
                                            }}
                                          >
                                            <div
                                              style={{
                                                alignItems: "center",
                                                justifyContent: "center",
                                                textAlign: "center",
                                              }}
                                            >
                                              <div
                                              //style={{ width: "auto", maxWidth: "120px" }}
                                              >
                                                <img
                                                  src={
                                                    filter?.logo
                                                      ? filter?.logo
                                                      : require("../../../assets/images/defaultImg.png")
                                                  }
                                                  class="card-img"
                                                  alt="Logo"
                                                  style={{
                                                    width: "auto",
                                                    height: "auto",
                                                    maxWidth: "220px",
                                                    maxHeight: "120px",
                                                  }}
                                                />
                                              </div>
                                              <br />
                                              <p
                                                style={{
                                                  marginTop: "-20px",

                                                  marginBottom: "10px",
                                                }}
                                              >
                                                {filter?.company_name}
                                              </p>
                                            </div>

                                            <div
                                              style={{ textAlign: "center" }}
                                            >
                                              <h5
                                                class="card-title"
                                                style={{
                                                  wordWrap: "break-word",
                                                }}
                                              >
                                                Special Resolution
                                              </h5>
                                              {/* <p
                                    style={{
                                      marginTop: "-5px",
                                      paddingRight: "10px",
                                      maxWidth: "335px",
                                    }}
                                    class="card-text"
                                  >
                                    {filter?.company_name}
                                  </p> */}

                                              <p
                                                style={{
                                                  marginTop: "-5px",
                                                  paddingRight: "10px",
                                                  maxWidth: "335px",
                                                }}
                                                class="card-text"
                                              >
                                                {moment(
                                                  item?.data?.agenda_from
                                                ).format("DD-MMM-YYYY h:mmA")}
                                                <br />
                                                TO
                                                <br />
                                                {moment(
                                                  item?.data?.agenda_to
                                                ).format("DD-MMM-YYYY h:mmA")}
                                              </p>
                                              <div>
                                                <div
                                                  className="d-flex "
                                                  style={{
                                                    marginTop: "-5px",
                                                    justifyContent:
                                                      "space-between",
                                                    width: "100%",
                                                  }}
                                                >
                                                  <p
                                                    style={{
                                                      marginTop: "-5px",
                                                    }}
                                                    class="card-text col-md-6 text-nowrap"
                                                  >
                                                    Shares:{" "}
                                                    <b>
                                                      {numberWithCommas(
                                                        item?.holding
                                                      )}
                                                    </b>
                                                  </p>
                                                  <p
                                                    style={{
                                                      marginTop: "-5px",
                                                    }}
                                                    class="card-text col-md-6 text-nowrap"
                                                  >
                                                    Folio #:{" "}
                                                    <b>
                                                      {item?.folio_number
                                                        .split("-")
                                                        .slice(1)
                                                        .join("-")}
                                                    </b>
                                                  </p>
                                                </div>

                                                {new Date(
                                                  currentDate
                                                ).getTime() <
                                                new Date(
                                                  startDate
                                                ).getTime() ? (
                                                  <button
                                                    className="btn"
                                                    type="button"
                                                    style={{
                                                      color: "#ffff",
                                                      background: "#5B9BD5",
                                                      marginTop: "-15px",
                                                      minWidth: "170px",
                                                      maxWidth: "170px",
                                                      textAlign: "center",
                                                    }}
                                                    disabled={true}
                                                  >
                                                    <span>
                                                      {" "}
                                                      Yet Not Started
                                                    </span>
                                                  </button>
                                                ) : new Date(
                                                    currentDate
                                                  ).getTime() >=
                                                    new Date(
                                                      startDate
                                                    ).getTime() &&
                                                  new Date(
                                                    currentDate
                                                  ).getTime() <=
                                                    new Date(
                                                      endDate
                                                    ).getTime() ? (
                                                  <button
                                                    className="btn"
                                                    type="button"
                                                    style={{
                                                      color: "#ffff",
                                                      background: "#5B9BD5",
                                                      marginTop: "-15px",
                                                      width: "190px",
                                                      textAlign: "center",
                                                    }}
                                                    onClick={() => {
                                                      if (index < 0) {
                                                        if (
                                                          new Date().getTime() >=
                                                          new Date(
                                                            endDate
                                                          ).getTime()
                                                        ) {
                                                          toast.error(
                                                            "Voting Time Expired"
                                                          );
                                                          setTimeout(() => {
                                                            window.location.reload();
                                                          }, 3000);
                                                        } else {
                                                          // localStorage.setItem(
                                                          //   "shareholdervotecast",
                                                          //   JSON.stringify(
                                                          //     item.folio_number
                                                          //   )
                                                          // );
                                                          sessionStorage.setItem(
                                                            "shareholdervotecast",
                                                            JSON.stringify(
                                                              item.folio_number
                                                            )
                                                          );
                                                          item["logo"] =
                                                            filter?.logo;
                                                          handleSpecialResolutionVoteCast(
                                                            item
                                                          );
                                                        }
                                                      } else {
                                                        setVotedResolutionDetail(
                                                          {
                                                            logo: filter?.logo,
                                                            from_date: moment(
                                                              item?.data
                                                                ?.agenda_from
                                                            ).format(
                                                              "DD-MMM-YYYY h:mmA"
                                                            ),
                                                            to_date: moment(
                                                              item?.data
                                                                ?.agenda_to
                                                            ).format(
                                                              "DD-MMM-YYYY h:mmA"
                                                            ),
                                                          }
                                                        );

                                                        setResolutioVotingView(
                                                          votedResolutionElection[
                                                            index
                                                          ]
                                                        );
                                                        setSpecialVotingDetail(
                                                          true
                                                        );
                                                      }
                                                    }}
                                                  >
                                                    {index < 0
                                                      ? " Cast Vote "
                                                      : "View Casted Vote"}
                                                  </button>
                                                ) : (
                                                  <button
                                                    className="btn"
                                                    type="button"
                                                    style={{
                                                      color: "#ffff",
                                                      background: "#5B9BD5",
                                                      marginTop: "-15px",
                                                      width: "210px",
                                                      textAlign: "center",
                                                    }}
                                                    disabled={true}
                                                  >
                                                    <span>
                                                      Voting Time Expired
                                                    </span>
                                                  </button>
                                                )}
                                              </div>
                                              {item?.title?.includes(
                                                "Special Resolution"
                                              ) && (
                                                <button
                                                  className="btn"
                                                  type="button"
                                                  style={{
                                                    color: "#ffff",
                                                    background: "#5B9BD5",
                                                    marginTop: "-15px",
                                                  }}
                                                  // disabled={true}
                                                  onClick={() => {
                                                    handleViewResolution(item);
                                                  }}
                                                >
                                                  <span> View Resolution</span>
                                                </button>
                                              )}
                                              {item?.waitingResult && (
                                                <button
                                                  className="btn"
                                                  type="button"
                                                  style={{
                                                    color: "#ffff",
                                                    background: "#5B9BD5",
                                                    marginTop: "-15px",
                                                  }}
                                                  disabled={true}
                                                >
                                                  <span> Result</span>
                                                </button>
                                              )}
                                              {item?.specialvoting && (
                                                <button
                                                  className="btn"
                                                  type="button"
                                                  style={{
                                                    color: "#ffff",
                                                    background: "#5B9BD5",
                                                    marginTop: "-15px",
                                                    minWidth: "170px",
                                                    maxWidth: "170px",
                                                  }}
                                                  onClick={() => {
                                                    viewSpecialVotingResult(
                                                      item
                                                    );
                                                  }}
                                                >
                                                  <span> Special Result</span>
                                                </button>
                                              )}
                                              {item?.electionvoting && (
                                                <button
                                                  className="btn"
                                                  type="button"
                                                  style={{
                                                    color: "#ffff",
                                                    background: "#5B9BD5",
                                                    marginTop: "-15px",
                                                    minWidth: "170px",
                                                    maxWidth: "170px",
                                                  }}
                                                  onClick={() => {
                                                    viewElectionResult(item);
                                                  }}
                                                >
                                                  <span> Election Result</span>
                                                </button>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <hr style={{ width: "100%" }} />
                                    </>
                                  );
                                })}
                            </div>
                          )}
                      </div>
                      {!loading &&
                        electionofDirectors.length == 0 &&
                        uniqueSpecialAgenda.length == 0 && (
                          <div
                            style={{
                              textAlign: "center",
                              marginBottom: "1rem",
                              fontWeight: "bold",
                            }}
                          >
                            There are currently no ongoing elections.{" "}
                          </div>
                        )}
                    </>
                  )}
              </div>
            </div>
          </ResponsiveWrapper>
        </MainWrapper>
      </div>
    </>
  );
};

const MainWrapper = styled.div`
  @media (min-width: 300px) {
    .responsivepage {
    }
  }
  @media (min-width: 500px) {
    .responsivepage {
      display: flex;
      justify-content: center;
      width: 70%;
    }
  }
`;
const ResponsiveWrapper = styled.div`
  @media (min-width: 300px) {
    .responsivhomePage {
    }
  }
  @media (min-width: 500px) {
    .responsivhomePage {
      min-width: 550px;
      max-width: 550px;
    }
  }
`;
const Wrapper = styled.div`
  @media @media (min-width: 300px) {
    .responsive {
      width: 80%;
    }
  }
  @media (min-width: 500px) {
    .responsive {
      width: 53%;
    }
  }
  @media (min-width: 660px) {
    .responsive {
      width: 45%;
    }
  }
  @media (min-width: 840px) {
    .responsive {
      width: 35%;
    }
  }
  @media (min-width: 1132px) {
    .responsive {
      width: 26%;
    }
  }

  @media (min-width: 1132px) {
    .responsive {
      width: 22%;
    }
  }

  @media (min-width: 1300px) {
    .responsive {
      width: 20%;
    }
  }
  @media (min-width: 1470px) {
    .responsive {
      width: 15%;
    }
  }
`;
const PaddingWrapper = styled.div`
  @media (min-width: 300px) {
    .responsivepaddingpage {
    }
    .imageStyle {
      max-width: 80px;
      max-height: 80px;
    }
  }
  @media (min-width: 500px) {
    .responsivepaddingpage {
      padding: 0px 100px;
    }
  }
`;
const ModelPaddingWrapper = styled.div`
  @media (min-width: 300px) {
    .responsivemodelpaddingpage {
    }
  }
  @media (min-width: 500px) {
    .responsivemodelpaddingpage {
      padding: 0px 20px;
    }
  }
`;
